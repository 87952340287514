.Button.Button {
  $bg-primary: #512da8;
  $bg-secondary: #fff;

  background-color: $bg-secondary;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  color: #4a4a4a;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  min-height: 47px;
  min-width: 160px;
  padding: 0 20px;
  text-transform: uppercase;
  transition: background-color 250ms, opacity 250ms;
  white-space: nowrap;
  position: relative;

  & + & {
    margin-left: 15px;
  }

  &::before {
    background-color: #000;
    border-radius: 3px;
    bottom: 0;
    content: ' ';
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 250ms;
  }

  &.anchor {
    display: inline-block;
    line-height: 47px;
    text-align: center;
    text-decoration: none;

    &.small {
      line-height: 32px;
    }
  }

  &.small {
    min-height: 32px;
    font-size: 13px;
  }

  &.medium {
    min-height: 47px;
    font-size: 14px;
  }

  &.large {
    min-height: 47px;
    font-size: 14px;
  }

  &.primary {
    border: none;
    background-color: $bg-primary;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.24);
  }

  &:not(.disabled),
  &:not([disabled]) {
    &:hover,
    &:active,
    &.active {
      &::before {
        opacity: 0.1;
      }
    }
  }

  // &:not(.disabled),
  // &:not([disabled]) {
  //   &:hover,
  //   &:active,
  //   &.active {
  //     background-color: darken($bg-secondary, 10);
  //   }

  //   &.primary {
  //     &:hover,
  //     &:active,
  //     &.active {
  //       background-color: darken($bg-primary, 10);
  //     }
  //   }
  // }

  &.disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;

    &:not(.primary) {
      background-color: darken($bg-secondary, 10);
    }
  }

  span {
    position: relative;
  }
}
