.inputPadder {
  padding: 0 10px;
}

.dropWrapper {
  padding-right: 40px;
  position: relative;
  width: 100%;
}
.dropZone {
  // alignItems: center;
  border-color: #dadada;
  border-radius: 5px;
  border-style: dashed;
  border-width: 2px;
  cursor: pointer;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  min-height: 45px;
  padding: 10px;
  width: 100%;
}
.dropImage {
  display: block;
  width: 100%;
}
.dropClear {
  color: #512da8;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &:hover {
    text-decoration: underline;
  }
}
.dropPrompt {
  line-height: 1.2;
  color: #7a7a7a;
}

.colorWrapper {
  padding-right: 15px;
  position: relative;
}
.colorPreview {
  border: 1px solid rgb(218, 218, 218);
  border-radius: 3px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 10px;
}
