.container.container {
  .mobileShow {
    display: none;
  }

  @media screen and (max-width: 750px) {
    .table {
      width: 100% !important;
    }

    .buttonContainer {
      margin-top: 30px !important;
    }

    .button {
      font-size: 14px !important;
      min-height: 47px !important;
      width: 100% !important;
    }

    .spacedBtn {
      margin-right: 0 !important;
      margin-bottom: 15px !important;
    }

    .mobileShow {
      display: block !important;
    }

    .mobileHide {
      display: none !important;
    }

    .mobileBlock {
      display: block !important;
    }

    .inputRow {
      position: relative !important;
    }

    .label {
      font-size: 16px !important;
      margin-bottom: 5px !important;
      font-weight: 700 !important;
    }

    .hintText {
      font-size: 12px !important;
      font-style: italic;
      font-weight: 400;
      display: block;
      margin-top: 7px;
    }

    .value {
      width: 100% !important;
      position: relative !important;
    }

    .valueReadOnly {
      padding-right: 30px !important;
    }

    .readOnlyIcon {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
    }

    .validationRow {
      margin-bottom: 30px !important;
    }
  }
}
