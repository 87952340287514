.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.tabs {
  margin-bottom: 30px;
}

.fieldOverrideContainer {
  padding: 9px 20px;
  border-radius: 100px;
  border: 1px solid #979797;
  display: inline-block;
  margin-bottom: 30px;
}

.fieldOverrideContainer_header {
  margin-bottom: 0;
  border: none;
  background-color: #fff;
  padding: 4px 20px;
}

.fieldOverrideLabel {
  font-size: 13px;
  margin-right: 13px;
  display: inline-block;
  line-height: 1;
}

.hintText {
  font-style: italic;
  margin: 0;
  opacity: 0.9;
  font-size: 13px;
  flex: 1;
}
