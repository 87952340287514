.actionLink {
  color: #215fac;
  cursor: pointer;
  margin-left: 5px;
  text-decoration: underline;
}

.label {
  font-size: 0.9em;
  font-weight: 700;
  padding: 5px 10px 5px 0;
  width: 150px;
}

.table {
  width: 100%;
}

.tr {
  // Empty
}

.td {
  padding: 5px 10px;
}

.status {
  font-size: 11px;
  font-weight: 900;
  color: #fff;
  display: inline-block;
  line-height: 18px;
  padding: 0 10px;
  border-radius: 20px;
}

.statusConnected {
  background-color: #6ae6c6;
}

.statusDisconnected {
  background-color: #f82a2a;
}

.reconnect {
  background-color: rgb(245, 245, 245);
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgb(180, 180, 180);
  padding: 10px;
  min-height: 42px;
}

.reconnectHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.code {
  font-size: 12px;
  font-weight: 700;
  line-height: 32px;
  margin-left: 5px;
}

.downloadBtn {
  float: right;
}

.reconnectMessage {
  border-top: 1px solid rgb(180, 180, 180);
}

.reconnectCancel {
  float: right;
  margin-left: 0;
  margin-right: 10px;
  line-height: 32px;
}

.button {
  margin-top: 15px;
}
