.process {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: space-between;

  svg {
    display: block;
    margin: 0 auto;
  }

  figcaption {
    line-height: 1;
    margin-top: 5px;
  }

  .arrow {
    margin: 10px 0;
  }

  .item {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dualFigure {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
      width: 170px;
    }

    figcaption {
      font-size: 11px;
      font-weight: 400;
    }

    &:nth-child(2) {
      span:first-child {
        margin-top: 6px;
      }
    }

    &:nth-child(odd) {
      max-width: 195px;
      justify-content: space-between;

      & > span:first-child {
        font-size: 24px;
        font-weight: 300;
        display: block;
        min-height: 40px;
      }

      & > span:last-child {
        display: block;
        line-height: 1.38;
        margin-top: 20px;
        min-height: 55px;
      }
    }
  }
}

.skipModalCopy {
  text-align: left;

  h2 {
    margin: 0 0 20px;
  }

  li {
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;

    i {
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
}
