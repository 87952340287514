.modal {
  padding: 30px 30px 70px;
}

.modalHeader {
  margin: 0;
  color: #512da8;
  font-size: 30px;
}

.tableContainer {
  max-height: calc(95vh - 100px - 55px);
  overflow: auto;
}

.detailImage {
  width: 100px;
  height: auto;
}
