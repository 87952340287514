.container {
  align-items: flex-end;
  border-bottom: 3px solid #bababa;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  &.disabled {
    border-bottom-color: #d4d4d4;
    opacity: 0.6;
  }
}

.option {
  align-items: center;
  color: #4a4a4a;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  position: relative;
  border: none;
  background: none;

  &:hover {
    z-index: 1;
  }

  &.disabled {
    cursor: not-allowed;
    filter: grayscale(1);

    > div,
    > span {
      opacity: 0.6;
    }
  }
}

.iconContainer {
  position: relative;

  span {
    display: block;
  }
}

.label {
  color: inherit;
  font-size: 13px;
  line-height: 1.7;
  margin: 10px 0 4px;
}

.tick {
  background-color: #9b9b9b;
  display: block;
  height: 5px;
  width: 1px;
}
.disabled .tick {
  background-color: #d4d4d4;
}

.slider {
  left: 50%;
  margin-top: -9px;
  margin-left: -10.5px;
  position: absolute;
  top: 100%;
  width: 21px;
  height: 21px;
  max-width: 21px;
}

.hide {
  display: none;
}

.lock {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: white;
  border: 1px solid #d4d4d4;
  width: 22px;
  height: 22px;
  padding: 4px;
  border-radius: 50%;

  &:hover {
    position: absolute !important;
  }
}

.iconContainer:hover .lock {
  z-index: 2;
}
