.container {
  text-align: center;
}

.topCopy {
  font-size: 13px;
  font-weight: 700;
}

.optionsContainer {
  background-color: white;
  /* border: 1px solid #d4d4d4;
  border-bottom: none; */
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto 0;
}

.option {
  border: 1px solid #d4d4d4;
  color: #4a4a4a;
  display: block;
  min-height: 180px;
  padding: 67px 10px 10px 170px;
  position: relative;
  text-align: left;
  width: 50%;
  background-color: white;
  transition: transform 200ms, box-shadow 200ms, border-right-width 0ms 200ms;

  & > * {
    transition: filter 200ms;
  }
  &:first-child:not(.active):not(:hover) {
    border-right-width: 0;
  }
  &:hover {
    text-decoration: none;
    box-shadow: 0 2px 2px 0 #d4d4d4;
    transform: scale(1.02);
    transition: transform 200ms, box-shadow 200ms, border-right-width 0ms;
    z-index: 1;
  }
  &.active {
    box-shadow: 0 2px 8px 0 #d4d4d4;
    transform: scale(1.1);
    transition: transform 200ms, box-shadow 200ms, border-right-width 0ms;
    z-index: 2;
  }
  &.inactive:not(:hover) > * {
    filter: blur(2px);
  }

  &Title {
    font-size: 24px;
    font-weight: 300;
    display: block;
  }

  &Copy {
    display: block;
    font-size: 11px;
    font-style: italic;
    line-height: 1.27;
    margin-top: 7px;
  }

  &Icon {
    align-items: flex-start;
    border-right: 3px solid #70ad47;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    height: 90px;
    justify-content: space-between;
    line-height: 1;
    margin-right: -150px;
    padding-right: 20px;
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
  &:first-child &Icon {
    border-right-color: #f2cb61;
  }
  &Icon svg {
    width: 68px;
    height: 71px;
  }

  &Details {
    border: 1px solid #d4d4d4;
    border-top: none;
    padding: 40px 70px 20px;
    text-align: left;
    width: 100%;
  }
}

// .permissionsTableContainer {
//   border: solid 1px #d4d4d4;
//   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
// }

.runContainer {
  font-size: 13px;
  text-align: center;
}

.runCopy {
  composes: topCopy;
  margin-bottom: 18px;
}

.runDetail {
  align-items: flex-start;
  counter-reset: numbered;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  padding: 30px 0;
  text-align: left;

  > div:first-child {
    padding-right: 100px;
    flex: 450;
  }

  > div:last-child {
    flex: 400;
  }

  ol,
  ul {
    counter-reset: numbered;
  }

  ol li,
  ul li {
    padding: 0 0 20px 20px;
    position: relative;

    &::before {
      content: counter(numbered) '.';
      counter-increment: numbered;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  ul li::before {
    content: '•';
  }
}

.runAllSet {
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  padding: 20px 0 55px;

  span {
    display: block;
  }

  &Icon {
    font-size: 80px;
    color: #f89c33;
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.skipModalCopy {
  text-align: left;

  h1 {
    margin: 0;
  }

  li {
    padding-left: 20px;
    position: relative;
    margin-bottom: 10px;

    i {
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
}
