.upgradeCopy {
  text-align: center;

  h1 {
    margin: 0;
  }

  p {
    max-width: 600px;
    margin: 40px auto;
  }
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 13px;

  tr:nth-child(even) {
    background-color: #eff5fc;
  }

  td {
    padding: 10px 20px;
    border-color: #d4d4d4;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    word-break: normal;
  }

  th {
    font-weight: normal;
    padding: 10px 20px;
    border-color: #d4d4d4;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    word-break: normal;
  }

  .tgtopheader {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #512da8;

    &:first-child {
      text-align: left;
    }
  }

  .tgsecondheader {
    background-color: rgba(249, 156, 52, 0.4);
    font-size: 15px;
    color: #4a4a4a;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .tgsecondheader:not(:last-child) {
    border-right-color: #fff;
  }
  .tgsubheader {
    text-align: left;
    color: #fff;
    background-color: #512da8;
    border-color: #512da8;
    font-weight: bold;
  }
  .tglabel {
    vertical-align: middle;
  }
  .tglabel a {
    text-decoration: underline;
  }
  .tglabel a:not(:hover) {
    color: #4a4a4a;
  }
  .tgchecklist {
    vertical-align: middle;
    text-align: center;
  }
}
