.Login.Login {
  .card {
    max-width: 400px;
    text-align: center;
    margin: 60px auto 40px;
    padding: 20px 40px 40px;
  }

  .img {
    vertical-align: middle;
    margin-right: 10px;
    height: 22px;
    width: auto;
  }

  .name {
    vertical-align: middle;
  }

  .button {
    text-align: left;
    min-width: 250px;
  }
}
