.container.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .topStuff {
    position: absolute;
    left: 15px;
    top: 10px;
    padding-left: 160px;

    .Select {
      width: 300px;
    }
  }

  .logo {
    left: 0;
    position: absolute;
    top: 10px;
  }

  .svg {
    cursor: grab;
    display: block;
    height: 100%;
    width: 100%;
  }

  .svgGrabbing {
    cursor: grabbing;
  }

  .node {
    cursor: pointer;

    .ouCircle {
      transition: fill 0.4s;
    }

    &.included .ouCircle {
      fill: #6ae6c6;
    }
    &.excluded .ouCircle {
      fill: #F82A2A;
    }

    .settingCircle {
      opacity: 0;
      transition: opacity 0.4s;
    }

    &.isSetting .settingCircle {
      opacity: 1;
    }

    circle {
      fill: #999;
    }

    text {
      font-size: 10px;
      // text-shadow:
      //   0 1px 0 #fff,
      //   0 -1px 0 #fff,
      //   1px 0 0 #fff,
      //   -1px 0 0 #fff;
      user-select: none;

      &::selection {
        background: none;
      }
    }
  }

  .nodeInternal circle {
    fill: #555;
  }

  .link {
    fill: none;
    stroke: #555;
    stroke-opacity: 0.4;
    stroke-width: 1.5px;
  }

  .tooltipGroup {
    display: none;

    &.isOpen {
      display: block;
    }
  }

  .menu {
    cursor: pointer;

    .button {
      fill: #f3f3f3;
      stroke-width: 1px;
      stroke: #f6a623;
    }

    g:hover .button {
      fill: #d4d4d4;
    }

    text {
      font-size: 12px;
      text-shadow: none;
      text-anchor: middle;
      dominant-baseline: central;
      alignment-baseline: central;
    }

    &.isExcluded {
      .exclude {
        .button {
          fill: #9b9b9b;
        }
        text {
          fill: white;
        }
      }
    }

    &.isIncluded {
      .include {
        .button {
          fill: #9b9b9b;
        }
        text {
          fill: white;
        }
      }
    }

    &.isClear {
      .clear {
        .button {
          fill: #9b9b9b;
        }
        text {
          fill: white;
        }
      }
    }
  }

  /* Edge is being a dick and not letting us have nice things. */
  /*@supports(filter: url(#shadow)) {
  .menu { filter: url(#shadow); }
}*/

  .zoomControlsContainer {
    position: absolute;
    bottom: 12px;
    left: 12px;
    display: flex;
    flex-direction: column;
  }

  .zoomButtonContainer {
    background: #fff;
    border: 1px solid #d4d4d4;
    box-shadow: 0 1px 2px 0 #d4d4d4;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  .zoomButton,
  .zoomButton_reset {
    cursor: pointer;
    padding: 8px;
    transition: background-color 250ms;

    svg {
      display: block;
      overflow: visible;
    }

    .zoomButtonG {
      fill: #9b9b9b;
      transition: fill 250ms;
    }

    &:hover {
      background-color: #f3f3f3;
      .zoomButtonG {
        fill: #4a90e2;
      }
    }
  }

  .zoomButton_reset {
    padding: 5px 8px 7px;
  }
}
