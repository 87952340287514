.filterContainer {
  display: flex;
  margin-bottom: 15px;
  align-items: center;

  > div,
  > span {
    flex: 1;
    &:first-child {
      margin-right: (15px / 2);
    }
    &:last-child {
      margin-left: (15px / 2);
    }
    &:first-child:last-child {
      margin: 0;
      width: 50%;
      width: calc(50% - (15px / 2));
      flex: none;
    }
  }

  .resetContainer {
    text-align: right;
  }

  .resetButton {
    padding-right: 16px;
  }
}

.notificationContainer {
  display: flex;
}

.table {
  margin-top: 20px;
}

.percent {
  display: inline-block;
  font-size: 26px;
  font-weight: 300;
  letter-spacing: -1px;
  margin-left: 15px;
  vertical-align: middle;
  width: 65px;
  text-align: left;
}

.graphButton {
  -webkit-appearance: none;
  font-size: 16px;
  background: none;
  border: none;
  color: #512da8;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  &[disabled] {
    color: #d5d5d5;
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      opacity: 0.6;
    }
  }
}
