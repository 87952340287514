.container {
  height: 440px;
  padding: 20px 0;
  position: relative;

  :global(.recharts-legend-item-text) {
    vertical-align: middle;
  }
}

.legendLabel {
  text-align: center;
  font-size: 12px;
  font-style: italic;
  color: #d4d4d4;
}

.emptyMessage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;

  p {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    font-size: 22px;
    font-style: italic;
    font-weight: 300;
  }
}
