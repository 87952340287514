$breakpoint: 750px;

.container.container.container {
  max-width: 980px;
  color: #4a4a4a;
  padding-top: 45px;
  padding-bottom: 65px;

  @media screen and (max-width: $breakpoint) {
    padding-left: 0;
    padding-right: 0;
  }
}

.banner {
  background-color: #3c454f;
  color: #ccc;
  text-align: center;
  padding: 5px 20px;
}
.approvalBanner {
  background-color: rgba(248, 156, 51, 0.1);
  text-align: center;
  padding: 5px 20px;
}
.approvalBannerContainer {
  display: inline-block;
  text-align: left;
  padding-left: 30px;
  position: relative;
}
.approvalBannerTextContainer {
  display: block;
  padding-left: 10px;
  border-left: 2px solid #f89c33;
}
.approvalIcon {
  color: #f89c33;
  display: block;
  font-size: 20px;
  line-height: 15px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.correctBanner {
  background-color: rgba(248, 156, 51, 0.1);
  text-align: left;
  padding: 18px 20px;

  @media screen and (max-width: 920px) {
    text-align: center;
  }
}
.correctBannerContainer {
  display: inline-block;
  text-align: left;
  padding-left: 30px;
  margin-bottom: ((32px - 24) / 2);
  margin-top: ((32px - 24) / 2);
  position: relative;
}
.correctBannerTextContainer {
  display: block;
  padding-left: 10px;
  border-left: 2px solid #f89c33;
}
.correctBannerButtonContainer {
  float: right;

  @media screen and (max-width: 920px) {
    clear: both;
    float: none;
    margin: 10px auto;
  }
}
.correctBannerButton {
  min-width: 150px;
  margin-left: 13px;
}

// SECTION
.sectionHeader {
  font-size: 14px;
  font-weight: 700;
  left: 0;
  line-height: 60px;
  padding: 0 40px;
  position: absolute;
  right: 0;
  top: 0;

  @media screen and (max-width: $breakpoint) {
    padding: 0 20px;
  }
}
.sectionHeaderHoverable {
  cursor: pointer;

  &:hover {
    background-color: darken(#f3f3f3, 0.05);
  }
}
.sectionHeaderClosed {
  background-color: #f3f3f3;
}
.sectionEditIcon {
  color: #512da8;
  margin-left: 15px;
  font-size: 15px;
  display: inline-block;
  padding: 5px;
  margin-top: -5px;
  line-height: 1;
  border-radius: 3px;
  background-color: transparent;
}
.sectionHeaderChevron {
  color: #512da8;
  font-size: 22px;
  line-height: 50px;
  position: absolute;
  right: 20px;
  top: 0;

  svg {
    vertical-align: middle;
  }
}
.sectionBtn {
  margin-top: 50px - 15;
}

.card {
  border-style: solid;
  border-color: #cfcfcf;
  border-width: 1px 1px 0 1px;
  font-size: 18px;
  line-height: 26px / 18;
  padding: 70px 20px 15px (180px + 40 + 1 + 40);
  position: relative;

  @media screen and (max-width: $breakpoint) {
    padding: 60px 20px 15px;
    border-width: 1px 0 0 0;
  }

  &:last-child {
    border-width: 1px;
    @media screen and (max-width: $breakpoint) {
      border-width: 1px 0 1px;
    }
  }
}
.cardEdit {
  padding: 70px 40px 30px;
}
.cardClosed {
  height: 60px;
  overflow: hidden;
  padding: 60px 0 0;

  @media screen and (maxw-dith: $breakpoint) {
    padding: 60px 0 0;
  }
}

.table {
  table-layout: fixed;

  @media screen and (max-width: $breakpoint) {
    width: 100%;
  }
}

.pendingBadge {
  background-color: #f2cb61;
  border-radius: 7px;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  font-weight: 700;
  height: 15px;
  line-height: 14px;
  margin-left: 5px;
  padding: 0 6px;
  text-align: center;
}
