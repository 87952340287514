.Card {
  border-style: solid;
  border-color: #cfcfcf;
  border-width: 1px 1px 0 1px;
  font-size: 18px;
  line-height: (26 / 18);
  padding: 70px 20px 15px (180px + 40 + 1 + 40);
  position: relative;
  border-radius: 3px;

  &_bottom {
    border-width: 1px;
  }
}
