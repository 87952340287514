.pager.pager {
  display: inline-block;
  background-color: #fff;
  border: 1px solid rgba(74, 74, 74, 0.3);
  margin-left: 8px;
  height: 34px;

  .pagerButton {
    border-radius: 0;
    border: none;
    padding: 0 8px;
    height: 32px;
    vertical-align: top;
  }

  span {
    border-left: 1px solid rgba(74, 74, 74, 0.3);
    border-right: 1px solid rgba(74, 74, 74, 0.3);
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    width: 30px;
  }
}
