.card {
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  margin-bottom: 0;
  padding-top: 50px;
  position: relative;
  text-align: center;
}

// If this isn't after .card, height won't be set right
.container {
  &#{&}#{&} {
    display: block;
    height: 218px;
    margin: 0 15px 15px 0;
    text-decoration: none;
    width: calc(50% - (15px / 2));
    &:hover {
      text-decoration: none;
    }
  }
}

.icon {
  border-radius: 50%;
  color: #fff;
  display: block;
  height: 30px;
  left: 10px;
  line-height: 25px;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 30px;
}
