@import '../../../../../components/Table/styles.module.scss';

.table {
  @extend .Table;
  position: relative;
}

.tableHeader {
  @extend .TableHeader;

  display: flex;
  align-items: center;

  p {
    margin: 0;
    line-height: inherit;
    flex: 1;
  }
}

.tableCell {
  @extend .TableCell;
  display: flex;
  align-items: center;

  &.isCheck,
  &.isCenter {
    justify-content: center;
  }
}

.row {
  @extend .TableRow;
  display: flex;

  div:first-child {
    flex: 1;
    border-left: none;
  }

  div:last-child {
    border-right: none;
  }

  &:last-child {
    border-bottom: none;
  }
}

.sectionIcon {
  vertical-align: middle;
  display: inline-block;
  margin-left: 8px;
}

.isDragging {
  border: $border;
}

.required {
  width: 150px;
}
.options {
  width: 200px;
}
.override {
  width: 150px;
}
.actions {
  width: 102px;
}

.addRow {
  background: none;
  &:hover {
    background: none;
  }
}

.addButton {
  background: none;
  border-radius: 3px;
  border: 1px dashed #B5E2FA;
  display: block;
  color: #4a90e2;
  text-align: left;
  width: 100%;
  padding: 9px 10px;
  font-size: 13px;
  cursor: pointer;

  &:hover,
  &:focus {
    border-style: solid;
    outline: none;
  }

  &:hover {
    transition: transform 250ms;
    transform: scale(1.01);
  }

  i {
    font-size: 16px;
  }
}

.attributeInherited {
  color: rgb(155, 155, 155);
}

.editAction,
.deleteAction {
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    cursor: not-allowed;
    color: #9b9b9b;
    &:hover {
      opacity: 1;
    }
  }
}

.editAction {
  color: #512da8;
}
.deleteAction {
  color: #F82A2A;
}

.savingOverlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-size: 36px;
    color: #9b9b9b;
  }
}

.droppableContainer {
  min-height: 25px;
  background-color: lighten(#d4d4d4, 15%);
}
