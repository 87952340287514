$border: 1px solid #d5d5d5;

.Table {
  background-color: #fff;
  font-size: 13px;
  width: 100%;
}

.TableRow {
  $rowBlue: #B5E2FA;
  $hoverBlue: rgba($rowBlue, 0.4);

  background-color: #fff;

  &.isAlt,
  .Table.autoAlt &:not(.isGroupHead):nth-child(odd) {
    background-color: #f8fafd;
    &:hover {
      background-color: $hoverBlue;
    }
  }

  &:hover {
    background-color: $hoverBlue;
  }

  &.isGroupHead {
    background-color: #d4d4d4;
  }

  &:last-child {
    border-bottom: $border;
  }
}

.TableHeader,
.TableCell {
  line-height: 1.3;
  padding: 9px 10px;
  vertical-align: middle;
  &.isCheck,
  &.isCenter {
    text-align: center;
  }
}

.TableCell {
  border-right: $border;
  &:first-child {
    border-left: $border;
  }

  &.isCheck {
    color: #6ae6c6;
    font-size: 14px;
  }
}

.TableHeader {
  text-align: left;
  font-weight: 700;
  line-height: 1.23;
  background: #fff;
  border-bottom: $border;
}
