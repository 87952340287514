@import '../../../../../assets/styles/utils/colors';

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.fieldOverrideContainer {
  padding: 9px 20px;
  border-radius: 100px;
  border: 1px solid #979797;
  display: inline-block;
  margin-bottom: 30px;
}

.fieldOverrideLabel {
  font-size: 13px;
  margin-right: 13px;
  display: inline-block;
  line-height: 1;
}

.tabs {
  margin-bottom: 30px;
}

.invalidMessage {
  color: $red;
}
