// .backdrop {
//   background-color: rgba(0, 0, 0, 0.6);
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }

// .container {
//   background-color: #fff;
//   border: solix 1px #979797;
//   box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.5);
//   max-width: 800px;
//   padding: 80px 50px 50px;
//   margin: 40px auto;
//   width: 95%;
// }

.header {
  font-size: 30px;
  margin: 0 0 15px;
  text-align: center;

  // @media screen and (max-width: $mobile-break) {
  //   font-size: 20px;
  //   font-weight: 700;
  // }
}

.bigIconContainer {
  display: block;
  text-align: center;
  margin: 0 0 28px;
}

.bigIcon {
  font-size: 80px;
}

.copy {
  font-size: 14px;
  margin: 15px 0 28px;
  text-align: center;
}
