/* Colors */
$blue: #512da8;
$lightBlue: #B5E2FA;
$gray: #9b9b9b;
$lightGray: #d4d4d4;
$otherLightGray: #d5d5d5;
$green: #6ae6c6;
$orange: #f2cb61;
$red: #f82a2a;

// Font colors
$color-text-dark: #3d3a3b;
$color-text-light: #fff;
$color-text-accent: #4a90e2;
