.approveWizard {
  font-size: 13px;
  height: 100%;
  position: relative;
  padding: 63px 0 70px 0;
}

.doneContainer {
  left: 50%;
  margin-top: -62.5px;
  padding: 0 35px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.doneContainer + .footerWrapper {
  margin-bottom: 39px;
}
.doneContainer h2 {
  font-weight: 400;
}

.title {
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding: 20px 0;
  position: absolute;
  top: 0;
}

.userName {
  font-weight: 700;
}

.attributeName {
  font-weight: 700;
  margin-bottom: 20px;
  display: block;
}

.changeWrapper {
  margin: 0 -15px;
  max-height: 100%;
  overflow: auto;
  padding: 0 15px;
}

.changeContainer {
  background-color: rgba(207, 221, 243, 0.2);
  margin: 15px -15px 0;
  min-height: 230px;
  padding: 20px 30px;
}

.imageChangeContainer {
  display: flex;
  justify-content: space-between;
  max-width: 230px;
}

.imageChangeContainer figure {
  line-height: 1;
}

.imageChangeContainer figcaption {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.imageChangeContainer img {
  display: inline-block;
  vertical-align: top;
  /*width: 50px !important;
  height: 50px !important;*/
}

.changeValueLabel {
  padding-right: 5px;
  padding-bottom: 20px;
}

.changeValueValue {
}

.buttonBase {
  background: none;
  border: 1px solid #d4d4d4;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 900;
  height: 50px;
  letter-spacing: 2px;
  transition: border-color 0.4s;
  vertical-align: middle;
  width: 50%;
}
.buttonBase:hover,
.buttonBase.active {
  border-color: currentColor;
}
.buttonBase[disabled] {
  cursor: not-allowed;
  color: #d4d4d4;
  opacity: 0.8;
}

.buttonReject {
  composes: buttonBase;
  color: #d0011b;
  border-right-color: transparent;
}
.buttonReject:hover,
.buttonReject.active {
  border-right-width: 1px;
}

.buttonApprove {
  composes: buttonBase;
  color: #6ae6c6;
}
.buttonReject:hover + .buttonApprove,
.buttonReject.active + .buttonApprove {
  border-left-color: transparent;
}

.footerWrapper {
  bottom: 30px;
  left: 15px;
  position: absolute;
  right: 15px;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.progressContainer {
  background-color: #d4d4d4;
  border-radius: 100px;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  overflow: hidden;
  position: relative;
  width: 100px;
}

.progressBar {
  background-color: #6ae6c6;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
}

.skipLink {
  /*cursor: pointer;*/
}
