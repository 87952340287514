.container {
  text-align: center;

  h1 {
    margin: 0;
  }

  p {
    max-width: 600px;
    margin: 40px auto;
  }
}
