$breakpoint: 750px;

.container.container.container {
  max-width: 980px;
  color: #4a4a4a;
  padding-top: 45px;
  padding-bottom: 65px;

  @media screen and (max-width: $breakpoint) {
    padding-left: 0;
    padding-right: 0;
  }
}
