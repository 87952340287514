.premiumModal.premiumModal {
  h1 {
    font-size: 30px;
    margin: 0 0 20px;
  }

  img {
    border: 1px solid #d5d5d5;
    display: block;
    margin-bottom: 40px;
    max-width: 460px;
  }

  p {
    // font-size: 18px;
    margin: 20px 0;
  }

  ol li {
    position: relative;
    padding: 0 0 10px 25px;

    &::before {
      content: '•';
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.learnMoreLink {
  font-size: 13px;
  margin-right: 20px;
}
